import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import { Link, navigate, useLocation } from '@reach/router'
import cn from 'classnames'
import qs from 'query-string'

import ArrowDown from '../assets/arrow-down.inline.svg'
import ArrowUp from '../assets/arrow-up.inline.svg'
import SearchIcon from '../assets/search.inline.svg'
import { MainLayout } from '../components'
import * as FAQsPageData from '../FAQs-data'

const order = ['tab', 'question', 'q']

export default function FAQPage() {
	const location = useLocation()
	const [filterQuery, setFilterQuery] = useState('')
	const [filteredQuestions, setFilteredQuestions] = useState([])
	const { tab: activeTab, question: activeQuestion, q: searchQuery } = qs.parse(
		location.hash
	)

	const updateQueryParams = useCallback(
		(newParams) => {
			navigate(
				`${location.pathname}#${qs.stringify(
					{
						...qs.parse(location.hash),
						...newParams
					},
					{
						skipNull: true,
						skipEmptyString: true,
						sort: (itemLeft, itemRight) =>
							order.indexOf(itemLeft) - order.indexOf(itemRight)
					}
				)}`,
				{ replace: true }
			)
		},
		[location.pathname, location.hash]
	)

	useEffect(() => {
		if (activeTab) {
			updateQueryParams({ q: filterQuery })
		}
	}, [activeTab, filterQuery, updateQueryParams])

	// useEffect(() => {
	// 	setFilteredQuestions()
	// }, [searchQuery])

	useEffect(() => {
		const firstTab = FAQsPageData.tabs?.[0].slug
		if (!activeTab) {
			if (firstTab) navigate(`/faq#tab=${firstTab}`, { replace: true })
		} else if (
			FAQsPageData.tabs.findIndex((el) => el.slug === activeTab) === -1
		) {
			navigate(`/faq#tab=${firstTab}`, { replace: true })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const curTab = FAQsPageData.tabs.find((el) => el.slug === activeTab)
		if (curTab?.slug !== 'contact-us') {
			if (searchQuery) {
				setFilteredQuestions(
					curTab
						? curTab?.questions?.filter((el) =>
								el.question.toLowerCase().includes(searchQuery.toLowerCase())
						  )
						: []
				)
			} else {
				setFilteredQuestions(curTab ? curTab?.questions : [])
			}
		}
	}, [activeTab, searchQuery])

	const toggleQuestionExpand = (question) => {
		navigate(
			`${location.pathname}#${qs.stringify(
				{
					...qs.parse(location.hash),
					question: activeQuestion === question ? null : question
				},
				{
					skipNull: true,
					sort: (itemLeft, itemRight) =>
						order.indexOf(itemLeft) - order.indexOf(itemRight)
				}
			)}`,
			{ replace: true }
		)
	}
	return (
		<>
			<MainLayout>
				<Helmet title="Deenee: FAQ" />
				<main className="container flex flex-col items-center justify-center">
					<header className="container relative max-w-full flex flex-col items-center justify-center">
						<h1 className="font-secondary pt-4 text-center text-2xl md:text-4xl leading-snug flex flex-col justify-start mb-10 bg-clip-text text-transparent bg-gradient-to-b primary-gradient">
							Frequently Asked Questions (FAQs), answered
						</h1>
					</header>
					<section className="w-full">
						<div className="relative w-full mb-4">
							<input
								placeholder="Search between questions"
								className="input placeholder-gray-500 w-full pl-4 pr-9 py-2"
								value={filterQuery}
								onChange={(e) => {
									setFilterQuery(e.target.value)
								}}
							/>
							<SearchIcon className="w-6 h-6 absolute right-3 top-1/2 transform -translate-y-1/2 fill-gray-500" />
						</div>
						<nav className="w-full flex flex-row flex-wrap items-center justify-center mb-4">
							{FAQsPageData.tabs.map((tab) => (
								<Link
									replace
									to={`${location.pathname}#${qs.stringify(
										{
											tab: tab.slug,
											q: searchQuery
										},
										{
											sort: (itemLeft, itemRight) =>
												order.indexOf(itemLeft) - order.indexOf(itemRight)
										}
									)}
								`}
									key={tab.slug}
									className={cn(
										'mx-1 px-3 py-2 font-secondary text-lg border-b-2 border-transparent hover:text-blue-300 focus:outline-none focus:text-blue-300',
										{
											'text-blue-500 border-b-2 border-blue-500':
												activeTab === tab.slug
										}
									)}
								>
									{tab.title}
								</Link>
							))}
						</nav>
						{FAQsPageData.tabs.map(
							(tab) =>
								activeTab === tab.slug &&
								(tab.slug === 'contact-us' ? (
									tab?.content
								) : (
									<nav className="mb-24 w-full" key={tab.slug}>
										<div className="w-full flex flex-col">
											{filteredQuestions.map((questionItem) => (
												<>
													<Link
														key={questionItem.slug}
														replace
														to={`${location.pathname}#${qs.stringify(
															{
																...qs.parse(location.hash),
																question: questionItem.slug
															},
															{
																sort: (itemLeft, itemRight) =>
																	order.indexOf(itemLeft) -
																	order.indexOf(itemRight)
															}
														)}`}
														onClick={(e) => {
															e.preventDefault()
															toggleQuestionExpand(questionItem.slug)
														}}
														className={cn(
															'w-full flex flex-row items-center justify-between px-3 py-2 border-b-2 border-gray-500 border-opacity-25',
															{
																'text-blue-500 border-blue-500 border-opacity-100':
																	activeQuestion === questionItem.slug
															}
														)}
													>
														<span>{questionItem.question}</span>
														{activeQuestion === questionItem.slug ? (
															<ArrowUp className="w-6 h-6 fill-gray-500" />
														) : (
															<ArrowDown className="w-6 h-6 fill-gray-500" />
														)}
													</Link>
													<div
														className={cn('w-full px-6 py-3', {
															hidden: activeQuestion !== questionItem.slug,
															'border-b-2 border-blue-500':
																activeQuestion === questionItem.slug
														})}
													>
														{questionItem.answer}
													</div>
												</>
											))}
										</div>
									</nav>
								))
						)}
					</section>
				</main>
			</MainLayout>
			<ToastContainer />
		</>
	)
}
