import React from 'react'

const aboutDeeneeQuestions = [
	{
		question: 'What is Deenee?',
		slug: 'what-is-deenee',
		answer: (
			<>
				<p className="mb-3">
					Deenee is the all-in-one Islamic education app for children between 7
					and 14 years. It helps your child learn and love Islam through fun
					bite-sized lessons, teaching all the Islamic essentials.
				</p>
				<p className="mb-3">
					We created Deenee with the belief that Islamic education should be on
					par with everything else that your child is learning. It should be a
					joyful and engaging experience. We want your child to be confident and
					proud of his or her religious identity.
				</p>
				<p>
					See the&nbsp;
					<a
						href="https://youtu.be/V2Jepfd7Thw"
						target="_blank"
						rel="noreferrer"
						className="font-bold text-blue-500 hover:text-blue-600 focus:text-blue-600"
					>
						explainer video
					</a>
					&nbsp;for a walk-through of what Deenee is about.
				</p>
			</>
		)
	},
	{
		question: 'Why we launched Deenee?',
		slug: 'why-we-launched-deenee',
		answer: (
			<ol className="list-decimal list-outside">
				<p>We created Deenee as a solution:</p>
				<li className="ml-4 pl-4">
					To provide a sound Islamic education to your child, to help him or her
					develop a sense of belonging to the muslim community and live and
					practice Islam with confidence
				</li>
				<li className="ml-4 pl-4">
					To empower your child to fulfil the rights of Allah in the manner that
					the Qurān and Allah’s messenger have prescribed.
				</li>
				<li className="ml-4 pl-4">
					To nurture your child&apos;s personality towards the best moral
					conduct, healthy attitudes and self-discipline in accordance with the
					teaching of the Qurān and Sunnah.
				</li>
				<li className="ml-4 pl-4">
					To encourage your child to develop into responsible citizens,
					contributing to the well-being of society and of humankind in general.
				</li>
			</ol>
		)
	},
	{
		question: 'Which subjects are covered in Deenee?',
		slug: 'which-subjects-are-covered-in-deenee',
		answer: (
			<>
				<ol className="list-decimal list-outside mb-3">
					<p>
						Deenee covers the Islamic essentials that every muslim should know.
						It is structured in the following subjects:
					</p>
					<li className="ml-4 pl-4">
						<strong>Aqīdah</strong>: The principles of Islamic faith which a
						person must believe in without any doubt, beginning with the
						declaration of the oneness of Allāh.
					</li>
					<li className="ml-4 pl-4">
						<strong>Akhlāq</strong>: Islamic etiquette, and manners in dealing
						with others and your surroundings.
					</li>
					<li className="ml-4 pl-4">
						<strong>Duʿās</strong>: Memorisation and understanding of essential
						daily duʿās.
					</li>
					<li className="ml-4 pl-4">
						<strong>Fiqh</strong>: Basic knowledge of Islamic laws and practical
						application in daily life, including wuḍū, prayers, fasting etc.
					</li>
					<li className="ml-4 pl-4">
						<strong>Ḥadīth</strong>: Memorisation and understanding of the
						sayings and teachings of Prophet Muḥammad SAW.
					</li>
					<li className="ml-4 pl-4">
						<strong>Tārīkh</strong>: Islamic history, including the lives of
						Prophet Muḥammad SAW, his companions, as well as other Prophets.
					</li>
				</ol>
				<p>
					Deenee also has a quiz to test general knowledge of Islam with
					hundreds of questions for the whole family to enjoy.
				</p>
			</>
		)
	},
	{
		question: 'Is the content safe and reliable?',
		slug: 'is-the-content-safe-and-reliable',
		answer: (
			<p>
				The content is structured in an age-appropriate manner with a
				progressive system of learning, based on various trusted Islamic studies
				books tested over 35 years. The content has been validated with Muslim
				scholars.
			</p>
		)
	},
	{
		question: 'What are the features of Deenee?',
		slug: 'what-are-the-features-of-deenee',
		answer: (
			<ol className="list-outside list-decimal">
				<p>
					Deenee is designed to make learning accessible, simple and engaging.
					The app has the following features:
				</p>
				<li className="italic ml-4 pl-4">
					<strong>10 academic levels per subject</strong>: Each level has on
					average 7 to 12 lessons or more.
				</li>
				<li className="italic ml-4 pl-4">
					<strong>Engaging content</strong>: with more than 5,000 interactive
					micro-lessons, quizzes, stories and audio.
				</li>
				<li className="italic ml-4 pl-4">
					<strong>Gamified experience</strong>: learning gets rewarded with
					coins, gems and trophies to keep your child engaged with their
					learning at all times Inshā Allāh.
				</li>
				<li className="italic ml-4 pl-4">
					<strong>Spaced repetition</strong>: to allow your child to
					automatically review difficult lessons more often.
				</li>
				<li className="italic ml-4 pl-4">
					<strong>Anytime, anywhere</strong>: your child can access the content
					anytime, anywhere.
				</li>
				<li className="italic ml-4 pl-4">
					<strong>Progress tracking</strong>: as a parent you can easily follow
					your child’s progress.
				</li>
				<li className="italic ml-4 pl-4">
					<strong>Motivate your child to apply the learning</strong>: reward
					your child with special gems for applying their learning in their
					daily life.
				</li>
			</ol>
		)
	},
	{
		question: 'How much time should my child spend on Deenee each week?',
		slug: 'how-much-time-should-my-child-spend-on-deenee-each-week',
		answer: (
			<p>
				Ideally, your child should be spending between 2 to 4 hours per week,
				learning on the app, spread over 2 to 4 days. This will allow them to
				get the most benefit out of the app. Apart from this, it is expected
				that your child takes time to practice what they are learning and apply
				the various concepts in their daily life.
			</p>
		)
	}
]

const useAsParentQuestions = [
	{
		question: "I'm a parent, how do I use the app?",
		slug: 'how-to-use-deenee-app-as-a-parent',
		answer: (
			<>
				<ol className="list-decimal list-outside mb-3">
					<p>
						The Parent Dashboard is where you can find out more about your
						child’s progress and learning journey with Deenee. In this section,
						you can find:
					</p>
					<li className="ml-4 pl-4">
						Your child’s progress and learning by subject, by week and total
					</li>
					<li className="ml-4 pl-4">Which lessons your child completed</li>
					<li className="ml-4 pl-4">
						Encourage your child by awarding a special gem for putting the
						lessons they have learnt into practice
					</li>
					<li className="ml-4 pl-4">
						Add new profiles or edit existing profiles for your children
					</li>
					<li className="ml-4 pl-4">Manage your settings</li>
				</ol>
				<ol className="list-decimal list-outside">
					<p>To access the Parents Dashboard:</p>
					<li className="ml-4 pl-4">
						Log In to your Deenee account on your Deenee iOS or Android app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select ‘Parent
						Dashboard.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						From the drop-down menu on the top left you can navigate to the
						various functions described above.
					</li>
				</ol>
			</>
		)
	},
	{
		question: 'Where can I see the progress of my child?',
		slug: 'where-can-i-see-the-progress-of-my-child',
		answer: (
			<ol className="list-decimal list-outside">
				<p>To see your child’s progress:</p>
				<li className="ml-4 pl-4">
					Log In to your Deenee account on your Deenee iOS or Android app.
				</li>
				<li className="ml-4 pl-4">
					Tap on the child profile icon on the bottom left and select ‘Parent
					Dashboard.
				</li>
				<li className="ml-4 pl-4">Enter your password.</li>
				<li className="ml-4 pl-4">
					From the drop-down menu on the top left you can select the child’s
					profile for which you want to see the progress.
				</li>
			</ol>
		)
	},
	{
		question: 'How can I, as a parent, support my child with their learning?',
		slug: 'how-can-i-as-a-parent-support-my-child-with-their-learning',
		answer: (
			<>
				<ol className="list-decimal list-outside mb-3">
					<p>You can support your child in the following ways:</p>
					<li className="ml-4 pl-4">
						Monitor the progress of your child, on a weekly basis, through the
						parent dashboard.
					</li>
					<li className="ml-4 pl-4">
						Help set weekly learning goals for your child.
					</li>
					<li className="ml-4 pl-4">
						Talk regularly with your child about their learning. What did they
						learn? What did they find difficult? What was surprising to them?
					</li>
					<li className="ml-4 pl-4">
						Encourage your child by awarding a special gem for putting the
						lessons they have learnt into practice.
					</li>
				</ol>
				<p>
					This will provide an enjoyable bonding routine between you and your
					child and ultimately enable your child to live and practice Islam with
					confidence
				</p>
			</>
		)
	}
]

const subscriptionsAndBillingQuestions = [
	{
		question: 'Why does a Deenee Plus membership cost money?',
		slug: 'why-does-a-deenee-plus-membership-cost-money',
		answer: (
			<>
				<p>
					Deenee is provided free of charge for level 1 of all subjects.
					However, for a small fee we are able to invest in our content and
					enhance your child’s learning experience.
				</p>
				<ul>
					<p>Deenee subscription revenue is utilised to:</p>
					<li className="ml-4 pl-4">- maintain customer service operations</li>
					<li className="ml-4 pl-4">
						- continuously improve the quality of the content, to make it more
						engaging and fun for your child
					</li>
					<li className="ml-4 pl-4">
						- translate the content into other languages, making it accessible
						in other countries where there is a need for Islamic education for
						children that is simple and engaging.
					</li>
				</ul>
			</>
		)
	},
	{
		question: 'How much does a Deenee Plus membership cost?',
		slug: 'how-much-does-a-deenee-plus-membership-cost',
		answer: (
			<>
				<p>
					Deenee offers a 14-day free trial with unlimited access to our content
					and only $6,99 per month thereafter! You may also select our:
				</p>
				<ul>
					<li className="ml-4 pl-4">
						- Six-month plan, which is $29,99/year ($5/month), or
					</li>
					<li className="ml-4 pl-4">
						- Annual plan, which is $49,99/year ($4.16/ per month).
					</li>
				</ul>
			</>
		)
	},
	{
		question: 'What do I get in return for the membership?',
		slug: 'what-do-i-get-in-return-for-the-membership',
		answer: (
			<ul>
				<p>As a Deenee Plus member you will get:</p>
				<li className="ml-4 pl-4">- access to all interactive micro-lessons</li>
				<li className="ml-4 pl-4">- unlimited access to the quizz</li>
				<li className="ml-4 pl-4">
					- spaced repetition – to automatically remind your child to practice
					difficult lessons more often
				</li>
				<li className="ml-4 pl-4">
					- The ability to reward your child with special gems for applying
					their learning in their daily life
				</li>
				<li className="ml-4 pl-4">
					- To help us continuously improve the quality of the content to make
					it more engaging and fun for your child
				</li>
				<li className="ml-4 pl-4">
					- To support our mission to make Islamic education for children around
					the world accessible, simple and engaging.
				</li>
			</ul>
		)
	},
	{
		question: 'Does each child need their own subscription?',
		slug: 'does-each-child-need-their-own-subscription',
		answer: (
			<p>
				No, a Deenee Plus membership allows up to 4 separate child profiles
				under 1 subscription. So only 1 subscription is required for up to four
				children! This allows for a personalised learning experience for each
				child. The same subscription can be shared on other devices, as long as
				it is the same Apple ID (iOS) or Google account (Android).
			</p>
		)
	},
	{
		question: 'How can I subscribe?',
		slug: 'how-can-i-subscribe',
		answer: (
			<>
				<p>
					Download and subscribe to Deenee through the Apple or Google Play app
					stores, using the credit card you have on file with the respective app
					store. Please note pricing and currency may vary by country.
				</p>
				<ol className="list-decimal list-outside">
					<li className="ml-4 pl-4">
						Log in to your Deenee account on your Deenee iOS or Android app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select
						&apos;Parent Dashboard&apos;.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						Tap on the menu (on the top-left) and select &apos;Settings&apos;.
					</li>
					<li className="ml-4 pl-4">Tap on &apos;Manage subscription&apos;.</li>
					<li className="ml-4 pl-4">
						Select your preferred subscription plan.
					</li>
				</ol>
			</>
		)
	},
	{
		question: 'Can I share the subscription on other devices?',
		slug: 'can-i-share-the-subscription-on-other-devices',
		answer: (
			<>
				<p className="mb-3">
					Yes, you can share your subscription on other devices. See below for
					more details.
				</p>
				<ol className="list-decimal list-outside mb-3">
					<p className="font-bold">iOS Subscription:</p>
					<p>
						To share your subscription on another iOS device, make sure you are
						logged in with the Apple ID that made the original purchase, before
						installing the app.
					</p>
					<li className="ml-4 pl-4">
						Log in to your Deenee account on your Deenee iOS app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select
						&apos;Parent Dashboard&apos;.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						Tap on the menu (on the top-left) and select &apos;Settings&apos;.
					</li>
					<li className="ml-4 pl-4">
						Tap on &apos;Restore purchases&apos; and follow the instructions.
					</li>
				</ol>
				<ol className="list-decimal list-outside">
					<p className="font-bold">Android Subscription:</p>
					<p>
						To share your subscription on another Android device, log in with
						the Google account that was used to make the original purchase,
						before installing the app.
					</p>
					<li className="ml-4 pl-4">
						Log in to your Deenee account on your Deenee Android app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select
						&apos;Parent Dashboard&apos;.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						Tap on the menu (on the top-left) and select &apos;Settings&apos;.
					</li>
					<li className="ml-4 pl-4">
						Tap on &apos;Restore purchases&apos; and follow the instructions.
					</li>
				</ol>
			</>
		)
	},
	{
		question: 'Does billing automatically recur every month?',
		slug: 'does-billing-automatically-recur-every-month',
		answer: (
			<p>
				For monthly subscriptions, payment recurs automatically each month.
				After the 14 day free trial period, you will be automatically charged
				the monthly subscription fee, should you choose to continue using the
				app. You won&apos;t be charged during your free trial and you can cancel
				anytime.
			</p>
		)
	},
	{
		question: 'How can I change my membership plan?',
		slug: 'how-can-i-change-my-membership-plan',
		answer: (
			<ol className="list-decimal list-outside">
				<p>
					To update or manage your payment details on the mobile app version of
					Deenee (iOS or Android):
				</p>
				<li className="ml-4 pl-4">
					Log in to your Deenee account on your Deenee iOS or Android app.
				</li>
				<li className="ml-4 pl-4">
					Tap on the child profile icon on the bottom left and select
					&apos;Parent Dashboard&apos;.
				</li>
				<li className="ml-4 pl-4">Enter your password.</li>
				<li className="ml-4 pl-4">
					Tap on the menu (on the top-left) and select &apos;Settings&apos;.
				</li>
				<li className="ml-4 pl-4">
					Tap on &apos;Manage subscription&apos; and follow the instructions.
				</li>
			</ol>
		)
	},
	{
		question: 'How can I cancel my membership?',
		slug: 'how-can-i-cancel-my-membership',
		answer: (
			<>
				<p className="mb-3">
					If you have decided to cancel, we&apos;re sorry to see you go and want
					to thank you for using Deenee. Please also note that after canceling,
					you will still have access to the services for the remainder of the
					time that you have already paid for (unless otherwise noted).
				</p>
				<ol className="list-decimal list-outside mb-3">
					<p className="font-bold italic">
						iOS / Apple App Store Subscription:
					</p>
					<p>
						If you have subscribed through Apple, you can easily cancel your
						Deenee subscription:
					</p>
					<li className="ml-4 pl-4">
						Log in to your Deenee account on your Deenee iOS app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select
						&apos;Parent Dashboard&apos;.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						Tap on the menu (on the top-left) and select &apos;Settings&apos;.
					</li>
					<li className="ml-4 pl-4">Tap on &apos;Manage subscription&apos;.</li>
					<li className="ml-4 pl-4">Tap on &apos;Cancel Subscription&apos;.</li>
				</ol>
				<p className="mb-3">
					Apple subscriptions and payments are managed by Apple directly. Due to
					Apple’s account management and user privacy policies, our support team
					is unable to cancel your subscription or provide refunds for
					subscriptions purchased through the App store.
				</p>
				<ol className="list-decimal list-outside mb-3">
					<p className="font-bold italic">
						Android / Google Play Subscription:
					</p>
					<p>
						If you are subscribed through Google Play, you can easily cancel
						your Deenee subscription:
					</p>
					<li className="ml-4 pl-4">
						Log in to your Deenee account on your Deenee Android app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select
						&apos;Parent Dashboard&apos;.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						Tap on the menu (on the top-left) and select &apos;Settings&apos;.
					</li>
					<li className="ml-4 pl-4">Tap on &apos;Manage subscription&apos;.</li>
					<li className="ml-4 pl-4">Tap on &apos;Cancel Subscription&apos;.</li>
				</ol>
				<em>
					Please note you must be signed in with the account that you used to
					subscribe to Deenee.
				</em>
			</>
		)
	}
]

const accountSettingsQuestions = [
	{
		question: 'How can I change my parent email ID for my Deenee account?',
		slug: 'how-can-i-change-my-parent-email-id-for-my-deenee-account',
		answer: (
			<ol className="list-decimal list-outside">
				<p>
					To change your email on the mobile app version of Deenee (iOS or
					Android):
				</p>
				<li className="ml-4 pl-4">
					Log in to your Deenee account on your Deenee Android app.
				</li>
				<li className="ml-4 pl-4">
					Tap on the child profile icon on the bottom left and select
					&apos;Parent Dashboard&apos;.
				</li>
				<li className="ml-4 pl-4">Enter your password.</li>
				<li className="ml-4 pl-4">
					Tap on the menu (on the top-left) and select &apos;Settings&apos;.
				</li>
				<li className="ml-4 pl-4">Make your edits in the email address box.</li>
				<li className="ml-4 pl-4">Save!</li>
			</ol>
		)
	},
	{
		question: 'How do I change my password?',
		slug: 'how-do-i-change-my-password',
		answer: (
			<ol className="list-decimal list-outside">
				<p>
					To change your password on the mobile app version of Deenee (iOS or
					Android):
				</p>
				<li className="ml-4 pl-4">
					Log in to your Deenee account on your Deenee app.
				</li>
				<li className="ml-4 pl-4">
					Tap on the child profile icon on the bottom left and select
					&apos;Parent Dashboard&apos;.
				</li>
				<li className="ml-4 pl-4">Enter your password.</li>
				<li className="ml-4 pl-4">
					Tap on the menu (on the top-left) and select &apos;Settings&apos;.
				</li>
				<li className="ml-4 pl-4">Make your edits in the password box.</li>
				<li className="ml-4 pl-4">Save!</li>
			</ol>
		)
	},
	{
		question: 'I forgot my password. What should I do?',
		slug: 'i-forgot-my-password-what-should-i-do',
		answer: (
			<>
				<p className="mb-3">
					Please tap on the &quot;Forgot Password?&quot; link located at the
					bottom of the sign-in page of the app. A password reset email will be
					sent to the email address linked to your Deenee account. If you
					don&apos;t receive the reset email within 15 minutes, please check
					your spam or junk folder.
				</p>
				<p>
					If you did not receive your password reset email or have trouble
					resetting your password, let us know! We&apos;re happy to help! Just
					send us an email at&nbsp;
					<a
						href="mailto:info@deeneeapp.com"
						target="_blank"
						rel="noreferrer"
						className="font-bold text-blue-500 hover:text-blue-600 focus:text-blue-600"
					>
						info@deeneeapp.com
					</a>
					.
				</p>
			</>
		)
	},
	{
		question: 'How do I unsubscribe from Deenee emails?',
		slug: 'how-do-i-unsubscribe-from-deenee-emails',
		answer: (
			<p>
				To unsubscribe from our emails, please click the &quot;Unsubscribe&quot;
				or &quot;Update Email Preferences&quot; link at the bottom of any email
				from Deenee. We&apos;re sorry to see that you&apos;ll no longer receive
				our emails, but thank you for being part of the Deenee community!
			</p>
		)
	},
	{
		question: 'How to turn off notifications?',
		slug: 'how-to-turn-off-notifications',
		answer: (
			<ol className="list-decimal list-outside">
				<p>
					There is an option to disable the notifications on Deenee from the
					tablet or mobile app. The Notifications feature must be turned off on
					each of your devices individually!
				</p>
				<li className="ml-4 pl-4">
					Log in to your Deenee account on your Deenee app.
				</li>
				<li className="ml-4 pl-4">
					Tap on the child profile icon on the bottom left and select
					&apos;Parent Dashboard&apos;.
				</li>
				<li className="ml-4 pl-4">Enter your password.</li>
				<li className="ml-4 pl-4">
					Tap on the menu (on the top-left) and select &apos;Settings&apos;.
				</li>
				<li className="ml-4 pl-4">
					Scroll down to &apos;Reminders & notifications&apos; and slide the
					toggle bar to the right.
				</li>
			</ol>
		)
	}
]

const childProfileManagementQuestions = [
	{
		question: 'How do I create a profile for my child(ren)?',
		slug: 'how-do-i-create-a-profile-for-my-child',
		answer: (
			<>
				<ol className="list-decimal list-outside mb-3">
					<p>
						Here&apos;s how to create a child profile on the mobile app version
						of Deenee (iOS or Android):
					</p>
					<li className="ml-4 pl-4">
						Log in to your Deenee account on your Deenee app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select
						&apos;Parent Dashboard&apos;.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						Tap on the menu (on the top-left) and select &apos;Add
						profile&apos;.
					</li>
					<li className="ml-4 pl-4">Follow the instructions from there.</li>
				</ol>
				<p>You can create up to 4 profiles on your Deenee account!</p>
			</>
		)
	},
	{
		question: "How do I add/edit my child's profile PIN?",
		slug: 'how-do-i-add-or-edit-my-child-s-profile-pin',
		answer: (
			<>
				<ol className="list-decimal list-outside mb-3">
					<p>
						Here&apos;s how to add or edit your child&apos;s profile PIN on the
						mobile app version of Deenee (iOS or Android):
					</p>
					<li className="ml-4 pl-4">
						Log Iin to your Deenee account on your Deenee app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select
						&apos;Parent Dashboard&apos;.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						Tap on the menu (on the top-left) and select your child&apos;s
						profile which you would like to edit.
					</li>
					<li className="ml-4 pl-4">Tap on the edit button on the avatar.</li>
					<li className="ml-4 pl-4">Make your edits in the PIN box.</li>
				</ol>
				<p>
					PIN numbers can be any 4-digit number (letters or other characters
					cannot be used).
				</p>
			</>
		)
	},
	{
		question: "How do I change my child's avatar?",
		slug: 'how-do-i-change-my-child-s-avatar',
		answer: (
			<ol className="list-decimal list-outside">
				<p>
					Here&apos;s how to change your child&apos;s avatar on the mobile app
					version of Deenee (iOS or Android):
				</p>
				<li className="ml-4 pl-4">
					Log in to your Deenee account on your Deenee app.
				</li>
				<li className="ml-4 pl-4">
					Tap on the child profile icon on the bottom left and select
					&apos;Parent Dashboard&apos;.
				</li>
				<li className="ml-4 pl-4">Enter your password.</li>
				<li className="ml-4 pl-4">
					Tap on the menu (on the top-left) and select your child&apos;s profile
					which you would like to edit.
				</li>
				<li className="ml-4 pl-4">Tap on the edit button on the avatar.</li>
				<li className="ml-4 pl-4">Tap on the change on the avatar.</li>
				<li className="ml-4 pl-4">Choose your child&apos;s avatar.</li>
			</ol>
		)
	},
	{
		question: "How do I change my child's profile name?",
		slug: 'how-do-i-change-my-child-s-profile-name',
		answer: (
			<ol className="list-decimal list-outside">
				<p>
					Here&apos;s how to change your child&apos;s profile name on the mobile
					app version of Deenee (iOS or Android):
				</p>
				<li className="ml-4 pl-4">
					Log in to your Deenee account on your Deenee app.
				</li>
				<li className="ml-4 pl-4">
					Tap on the child profile icon on the bottom left and select
					&apos;Parent Dashboard&apos;.
				</li>
				<li className="ml-4 pl-4">Enter your password.</li>
				<li className="ml-4 pl-4">
					Tap on the menu (on the top-left) and select your child&apos;s profile
					which you would like to edit.
				</li>
				<li className="ml-4 pl-4">Tap on the edit text on the avatar.</li>
				<li className="ml-4 pl-4">
					Make your edits in the child&apos;s name box.
				</li>
			</ol>
		)
	},
	{
		question: 'How do I delete a child profile?',
		slug: 'how-do-i-delete-a-child-profile',
		answer: (
			<>
				<ol className="list-decimal list-outside mb-3">
					<p>
						Here&apos;s how to delete your child&apos;s profile on the mobile
						app version of Deenee (iOS or Android):
					</p>
					<li className="ml-4 pl-4">
						Log in to your Deenee account on your Deenee app.
					</li>
					<li className="ml-4 pl-4">
						Tap on the child profile icon on the bottom left and select
						&apos;Parent Dashboard&apos;.
					</li>
					<li className="ml-4 pl-4">Enter your password.</li>
					<li className="ml-4 pl-4">
						Tap on the menu (on the top-left) and select your child&apos;s
						profile you would like to delete.
					</li>
					<li className="ml-4 pl-4">Tap on the edit text on the avatar.</li>
					<li className="ml-4 pl-4">
						Scroll down and tap on &apos;Delete Profile&apos;.
					</li>
					<li className="ml-4 pl-4">
						Confirm by taping on &apos;Delete&apos;.
					</li>
				</ol>
				<p>
					Please note that you cannot delete the last child profile as there
					must be at least one profile in your account, in addition to the
					parent profile. However, if you create a newer child profile, you will
					be able to delete the older profile.
				</p>
			</>
		)
	}
]

// eslint-disable-next-line import/prefer-default-export
export const tabs = [
	{
		title: 'About Deenee',
		slug: 'about-deenee',
		questions: aboutDeeneeQuestions
	},
	{
		title: 'Using Deenee as a parent',
		slug: 'using-deenee-as-a-parent',
		questions: useAsParentQuestions
	},
	{
		title: 'Subscriptions & billing',
		slug: 'subscriptions-and-billing',
		questions: subscriptionsAndBillingQuestions
	},
	{
		title: 'Account settings',
		slug: 'account-settings',
		questions: accountSettingsQuestions
	},
	{
		title: 'Child profile management',
		slug: 'child-profile-management',
		questions: childProfileManagementQuestions
	},
	{
		title: 'Contact us',
		slug: 'contact-us',
		content: (
			<>
				<h3 className="font-bold">
					Still have questions? We&apos;re happy to help
				</h3>
				<p>
					Please drop us an email at&nbsp;
					<a
						href="mailto:info@deeneeapp.com"
						target="_blank"
						rel="noreferrer"
						className="font-bold text-blue-500 hover:text-blue-600 focus:text-blue-600"
					>
						info@deeneeapp.com
					</a>
					&nbsp;and we’ll get back to you as soon as possible.
				</p>
			</>
		)
	}
]
